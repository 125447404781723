import { db, ServerValue, auth, storage } from '@/firebase/db'
import { IPremioModel, FileUpload, ISolicitudPremioModel } from '@/store/types'
import store from '@/store'
import moment from 'moment'

export default {
  findById: (data: IPremioModel): Promise<IPremioModel> =>
    new Promise((resolve) => {
      db.ref(`premios/${data.Id}`).once('value', (snapshot: any) => {
        const _snapshot: any = { ...snapshot.val(), key: data.Id, Id: data.Id }

        resolve((_snapshot as unknown) as IPremioModel)

        // resolve({ ...snapshot.val(), key: data.Id, Id: data.Id } as IPremioModel)
      })
    }),

  findAll: (sort = true): Promise<IPremioModel[]> =>
    new Promise((resolve) => {
      db.ref(`premios`)
        .orderByChild('createdAt')
        .on('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))
          }

          const premios = (_snapshot as unknown) as IPremioModel[]

          // if (premios && !sort) {
          //   premios = premios.sort((a, b) => {
          //     return (moment(b.Fecha).valueOf() - moment(a.Fecha).valueOf()) as any
          //   })
          // } else if (premios) {
          //   premios = premios.sort((a, b) => {
          //     return (new Date(a.Fecha).getTime() - new Date(b.Fecha).getTime()) as any
          //   })
          // }

          resolve(premios)
        })
    }),

  remove: (data: IPremioModel): Promise<any> => db.ref(`premios/${data.Id}`).remove(),

  save: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        db.ref(`premios/${data['key']}`).set({ ...data, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        db.ref(`premios`).push({ ...data, createdAt: ServerValue, UsuarioCreacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    }),

  findByIdSolicitudPremio: (data: ISolicitudPremioModel): Promise<ISolicitudPremioModel> =>
    new Promise((resolve) => {
      db.ref(`solicitudpremios/${data.Id}`).once('value', (snapshot: any) => {
        const _snapshot: any = { ...snapshot.val(), key: data.Id, Id: data.Id }

        resolve((_snapshot as unknown) as ISolicitudPremioModel)

        // resolve({ ...snapshot.val(), key: data.Id, Id: data.Id } as ISolicitudPremioModel)
      })
    }),

  findAllSolicitudPremio: (sort = true): Promise<ISolicitudPremioModel[]> =>
    new Promise((resolve) => {
      db.ref(`solicitudpremios`)
        .orderByChild('createdAt')
        .on('value', (snapshot: any) => {
          let _snapshot: any = []
          if (snapshot.val()) {
            _snapshot = Object.entries(snapshot.val()).map((e) => Object.assign(e[1], { key: e[0], Id: e[0] }))
          }

          const premios = (_snapshot as unknown) as ISolicitudPremioModel[]

          resolve(premios)
        })
    }),

  removeSolicitudPremio: (data: ISolicitudPremioModel): Promise<any> => db.ref(`solicitudpremios/${data.Id}`).remove(),

  saveSolicitudPremio: (data: any): Promise<any> =>
    new Promise((resolve) => {
      if (data['key']) {
        db.ref(`solicitudpremios/${data['key']}`).set({ ...data, updatedAt: ServerValue, UsuarioModificacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      } else {
        db.ref(`solicitudpremios`).push({ ...data, createdAt: ServerValue, UsuarioCreacion: store.getters['auth/username'] }, (snapshot: any) => {
          resolve(snapshot)
        })
      }
    }),

  fileUpload: (data: FileUpload, ruta: IPremioModel): Promise<any> =>
    new Promise((resolve, reject) => {
      const storageRef = storage.ref(`/Imagenes/Premios/${ruta.Id}/${data.name}`).put(data.file)
      storageRef.on(
        `state_changed`,
        (snapshot: any) => {
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        },
        (error: any) => {
          console.log(error.message)
          reject(error)
        },
        () => {
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url: any) => {
              console.log(url)
              resolve(url)
            })
            .catch((error: any) => {
              console.log(error)
              reject(error)
            })
        }
      )
    })
}
