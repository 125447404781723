






































































































































import Vue from 'vue'
// import { db } from '@/firebase/db'
/**  Model **/
import { IPremioModel } from '@/store/types'

/**  Service **/
import PremioService from '@/services/PremioService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Premios rutas',
        subtitle: '',
        message: 'En esta página podrás encontrar todos los premios rutas que han sido creados.'
      },

      form: {
        loading: true,
        dialog: false,
        valid: false,
        record: { Activo: true } as IPremioModel,
        rules: {
          required: [(v: any) => !!v || 'Este campo es requerido']
        }
      },

      table: {
        loading: true,
        filters: {
          search: null,
          rol: null
        },
        headers: [
          { text: 'Nombre', value: 'Nombre', align: 'left' },
          { text: 'Descripción', value: 'Descripcion', align: 'left' },
          { text: 'Puntos', value: 'Puntos', align: 'left' },
          { text: 'Secuencia', value: 'Secuencia', align: 'left' },
          { text: 'Url', value: 'Url', align: 'left' },
          { text: 'Activo', value: 'Activo', align: 'center' },
          { text: 'Creación', value: 'UsuarioCreacion', align: 'left' },
          { text: 'Ultima modificación', value: 'UsuarioModificacion', align: 'left' },
          { text: 'Acciones', align: 'center' }
        ],
        records: [],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  components: {},

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    formatPrice(value: number, simbol = true) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      const s = simbol ? '$ ' : ''
      return (
        s +
        val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .replace(',00', '')
      )
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      this.table.records = (await PremioService.findAll()) as any
      this.$store.dispatch('app/hideLoading')
    },

    add() {
      this.form.dialog = true
      this.form.record = { Activo: true } as IPremioModel
    },

    async edit(item: IPremioModel) {
      this.$store.dispatch('app/showLoading')
      this.form.record = await PremioService.findById(item)
      this.form.dialog = true
      this.$store.dispatch('app/hideLoading')
    },

    async save() {
      if (this.formRecord.validate()) {
        this.$store.dispatch('app/showLoading')
        await PremioService.save({ ...this.form.record } as IPremioModel)
        this.$store.dispatch('app/hideLoading')
        this.reset()
        this.load()
        this.$store.dispatch('app/alertPrompt', {
          text: 'Proceso realizado correctamente.',
          type: 'success'
        })
      }
    },

    reset() {
      this.form.dialog = false
      this.form.record = { Activo: true } as IPremioModel
    }
  }
})
